.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.logo-image-size {
  /* width: 100px; */
  height: auto;
  display: block; /* Ajusta el tamaño de la imagen correctamente */
  position: relative; /* Asegura que la imagen esté encima de la sombra */
}


.color-nav-bar{
  background-color: #D39864;
}

.div-confirmation-page{
  margin-top: 30px;
  min-height: calc(100vh - 40px - 40px);
}
.div-admin-page{
  margin-top: 30px;
  min-height: calc(100vh - 40px - 40px);
}

.div-footer{
  margin-top: auto;
  padding-top: 50px;

  /* position: fixed;
  bottom: 0;
  width: 100%; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.map-container {
    width: 100%;
    height: 400px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 2px 4px 12px 0px rgba(204,200,204,1);
    border: #D5D5D5 3px solid;
}
.map-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}